<template>
  <div>
    <div>
      <div>
        <b-button
          class="actionBtn btn--primary"
          @click="onClick('Start')"
          :disabled="isDisabled"
        >
          <font-awesome-icon icon="power-off" />
          <div class="pl-1">Start transaction</div>
        </b-button>
      </div>
      <div>
        <b-button
          class="actionBtn btn--primary mt-1"
          @click="onClick('Stop')"
          :disabled="isDisabled"
        >
          <font-awesome-icon icon="stop" />
          <div class="pl-1">Stop transaction</div>
        </b-button>
      </div>
      <div>
        <b-button
          class="actionBtn btn--primary mt-1"
          @click="onClick('Unlock')"
          :disabled="isDisabled"
        >
          <font-awesome-icon icon="unlock-alt" />
          <div class="pl-1">Unlock</div>
        </b-button>
      </div>
      <div>
        <b-button
          class="actionBtn btn--primary mt-1"
          @click="showConfirmAlert('Disconnect')"
          :disabled="isDisabled"
        >
          <font-awesome-icon icon="sync-alt" />
          <div class="pl-1">Reconnect</div>
        </b-button>
      </div>
      <div>
        <template v-if="ocppVersion === 'V_16'">
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('getDiagnostics')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="arrow-circle-down" />
              <div class="pl-1">Get Diagnostics</div>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('SoftReset')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="redo-alt" />
              <div class="pl-1">Soft reset</div>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('HardReset')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="redo-alt" />
              <div class="pl-1">Hard reset</div>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              v-b-modal.scheduleModal
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="arrow-down" />
              <div class="pl-1">Get Composite Schedule</div>
            </b-button>
          </div>
        </template>
        <template v-else-if="ocppVersion === 'V_20'">
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('OnIdleReset')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="redo-alt" />
              <div class="pl-1">On Idle reset</div>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('ImmediateReset')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="redo-alt" />
              <div class="pl-1">Immediate reset</div>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('ChangeAvailabilityInoperative')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="exchange-alt" />
              <span class="pl-1">Set Inoperative</span>
            </b-button>
          </div>
          <div>
            <b-button
              class="actionBtn btn--primary mt-1"
              @click="showConfirmAlert('ChangeAvailabilityOperative')"
              :disabled="isDisabled"
            >
              <font-awesome-icon icon="exchange-alt" />
              <div class="pl-1">Set Operative</div>
            </b-button>
          </div>
        </template>
      </div>
      <v-overlay :value="testConnectionInProgress">
        <v-icon class="white--text">mdi-power-standby</v-icon>
        Please standby, while we test connection
        <v-divider></v-divider>
        <span v-show="disconnectEvent">
          Charger disconnected, attempting to reconnect in
          <v-icon class="white--text">mdi-clock</v-icon>
          <strong>
            {{
              timerSeconds | prependZeroIncaseOfSingleDigit(timerMinutes)
            }}</strong
          ></span
        >
        <span
          v-show="chargerConnectionStatus === undefined"
          id="chargerOffline"
        >
          Charger is offline, waiting for charger to be online in
          <v-icon class="white--text">mdi-clock</v-icon>
          <strong>
            {{
              timerSeconds | prependZeroIncaseOfSingleDigit(timerMinutes)
            }}</strong
          ></span
        >
      </v-overlay>
      <div>
        <b-button
          class="actionBtn btn--primary mt-1"
          @click="triggerBtnOnClick()"
          :disabled="isDisabled"
        >
          <font-awesome-icon icon="sign-in-alt" />
          <div class="pl-1">Trigger</div>
          <b-dropdown
            right
            variant="gray"
            size="sm"
            class="ml-1 caret"
            no-caret
            ref="triggerDropdown"
            :disabled="isDisabled"
          >
            <template v-slot:button-content>
              <font-awesome-icon icon="caret-down" class="btn--primary mr-1" />
            </template>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="showConfirmAlert('TriggerHeartbeat')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                Heartbeat
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="onClick('TriggerMeterValues')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                MeterValues
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="showConfirmAlert('TriggerBootNotification')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                BootNotification
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="onClick('TriggerStatusNotification')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                StatusNotification
              </b-button>
            </b-dropdown-item>
            <template v-if="ocppVersion === 'V_20'">
              <b-dropdown-item>
                <b-button
                  class="actionBtn btn--primary"
                  @click="showConfirmAlert('TriggerSignV2gCertificate')"
                >
                  <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                  SignV2gCertificate
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  class="actionBtn btn--primary"
                  @click="showConfirmAlert('TriggerTransactionEvent')"
                >
                  <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                  TransactionEvent
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  class="actionBtn btn--primary"
                  @click="showConfirmAlert('TriggerLogStatusNotification')"
                >
                  <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                  LogStatusNotification
                </b-button>
              </b-dropdown-item>
            </template>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="showConfirmAlert('TriggerFirmwareStatusNotification')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                FirmwareStatusNotification
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="
                  showConfirmAlert('TriggerDiagnosticsStatusNotification')
                "
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                DiagnosticsStatusNotification
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button
                class="actionBtn btn--primary"
                @click="testConnection('TriggerChargerTestConnection')"
              >
                <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                Test Charger Connection
              </b-button>
            </b-dropdown-item>
            <template v-if="ocppVersion === 'V_20'">
              <b-dropdown-item>
                <b-button
                  class="actionBtn btn--primary"
                  @click="
                    showConfirmAlert('TriggerSignChargingStationCertificate')
                  "
                >
                  <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                  SignChargingStationCertificate
                </b-button>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </b-button>
      </div>
    </div>
    <div
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000"
      v-if="isLoading"
    >
      <b-spinner />
    </div>
    <div
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000"
      v-if="isConfirmLoading"
    >
      <b-spinner />
    </div>
    <b-alert
      :show="dismissCountDown"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000"
      :variant="variant"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      id="dismiss-alert"
    >
      <template>
        <div v-if="variant === 'success'">Success</div>
        <div v-else-if="variant === 'danger'">Failed</div>
      </template>
    </b-alert>
    <div class="text-center">
      <b-alert :show="confirmAlert" class="fixed-top" variant="light">
        <span class="text-dark">
          Are you sure?
          <b-button
            class="text-success mr-1 ml-4 border border-success confirmBtn"
            @click="triggerCommands(triggerAction)"
          >
            <font-awesome-icon icon="check" class="mr-1" />
            Yes
          </b-button>
          <b-button class="btn--primary confirmBtn" @click="hideConfirmAlert">
            <svg class="icon mr-1">
              <use xlink:href="@/assets/svg/icons.svg#x" />
            </svg>
            No
          </b-button>
        </span>
      </b-alert>
    </div>
    <b-modal
      id="connectorPickerModal"
      ref="connectorPickerModal"
      title="Select a connector"
      hide-footer
      @hidden="getCloseConnectorPickerModal"
    >
      <div class="my-4">
        <b-form @submit.stop.prevent="triggerCommands(selectedAction)">
          <b-form-group style="text-align: center">
            <b-form-radio
              v-model="selectedConnector"
              value="1"
              inline
              button
              button-variant="outline-success"
              size="lg"
            >
              Connector 1
            </b-form-radio>
            <b-form-radio
              v-model="selectedConnector"
              value="2"
              inline
              button
              button-variant="outline-success"
              size="lg"
            >
              Connector 2
            </b-form-radio>
          </b-form-group>
          <div style="text-align: center" class="mt-4">
            <b-button class="btn--primary fetchBtn" type="submit">
              OK
            </b-button>
            <b-button
              class="text-success ml-1 border border-success fetchBtn"
              @click="getCloseConnectorPickerModal"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="scheduleModal"
      ref="scheduleModal"
      title="Get Composite Schedule"
      hide-footer
      @hidden="getCloseScheduleModal"
    >
      <div class="my-4">
        <b-form @submit="triggerCommands('GetCompositeSchedule')" v-if="show">
          <b-form-group
            id="input-group-1"
            label="Duration:"
            label-for="inputDuration"
          >
            <b-container>
              <b-row>
                <b-form-input
                  id="inputDate"
                  type="date"
                  :formatter="dateFormatter"
                  v-model="form.durationDate"
                  required
                  class="w-50"
                />
                <b-form-input
                  id="inputTime"
                  type="time"
                  v-model="form.durationTime"
                  required
                  class="w-50"
                />
              </b-row>
            </b-container>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Charging Rate Unit:"
            label-for="inputRateUnit"
          >
            <b-form-select
              id="inputUnit"
              v-model="form.rateUnit"
              :options="[
                { text: 'Select Rate Unit', value: null },
                'Watt',
                'Ampere',
              ]"
              required
            />
          </b-form-group>
          <div class="mt-4">
            <b-button class="btn--primary fetchBtn" type="submit">
              Submit
            </b-button>
            <b-button
              class="text-success ml-1 border border-success fetchBtn"
              @click="getCloseScheduleModal"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue, BButton, BAlert, BSpinner } from "bootstrap-vue";
import * as api from "../api/CentralSystemApi";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faSyncAlt,
  faSignInAlt,
  faStop,
  faUnlockAlt,
  faPowerOff,
  faUnlink,
  faCheck,
  faRedoAlt,
  faCaretDown,
  faArrowCircleDown,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { putDiagnostics } from "@/api/GetDiagnosticsApi";
import { getCompositeSchedule } from "@/api/GetCompositeScheduleApi";
import moment from "moment";
import { triggerChargerCommands } from "@/api/CentralSystemApi";
import { getChargerEventByName } from "@/api/ChargerEventsApi";

library.add(
  faArrowDown,
  faSyncAlt,
  faSignInAlt,
  faStop,
  faPowerOff,
  faUnlink,
  faUnlockAlt,
  faSignInAlt,
  faCheck,
  faRedoAlt,
  faCaretDown,
  faArrowCircleDown,
  faExchangeAlt
);
Vue.use(BootstrapVue);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    "b-button": BButton,
    "b-alert": BAlert,
    "b-spinner": BSpinner,
  },
  name: "ChargerCommandsTrigger",
  props: ["chargerId", "charger", "isDisabled"],
  data() {
    return {
      isTriggered: false,
      triggerAction: "",
      confirmAlert: false,
      isLoading: false,
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      variant: "",
      isConfirmLoading: false,
      isCollapse: false,
      isClicked: false,
      moment: moment,
      tomorrowTime: moment().add(1, "days").format("HH:mm"),
      tomorrowDate: moment().add(1, "days").format("YYYY-MM-DD"),
      form: {
        rateUnit: "",
        durationTime: "",
        durationDate: "",
      },
      show: true,
      selectedConnector: 1,
      selectedAction: "",
      multiConnectorActions: [
        "Start",
        "Stop",
        "Unlock",
        "TriggerMeterValues",
        "TriggerStatusNotification",
      ],
      isStatusChangedToOnboarding: false,
      testConnectionInProgress: false,
      testConnectionResult: "",
      timerSeconds: 59,
      timerMinutes: 4,
      checkForEventsFrom: "",
      checkForConnectEventByPolling: "",
      disconnectEvent: false,
      connectEvent: "",
      chargerConnectionStatus: "",
    };
  },
  filters: {
    prependZeroIncaseOfSingleDigit(secondsValue, minutes) {
      const newVal = "0".concat(secondsValue).slice(-2);
      return `${minutes}:${newVal}`;
    },
  },
  methods: {
    triggerCommands(action) {
      const vm = this;
      const chargerId = vm.chargerId;
      const selectedConnector = vm.selectedConnector;
      const selectedDateTime = `${vm.form.durationDate} ${vm.form.durationTime}`;
      const duration = moment(selectedDateTime).diff(moment(), "seconds");
      const rateUnit = vm.form.rateUnit === "Watt" ? 0 : 1;

      if (action === "getDiagnostics") {
        putDiagnostics(chargerId)
          .then(function (response) {
            vm.variant = response.data.status ? "success" : "danger";
          })
          .catch((error) => {
            vm.variant = "danger";
            console.log(error);
          });
      } else if (action === "GetCompositeSchedule") {
        getCompositeSchedule(chargerId, duration, rateUnit)
          .then(function (response) {
            vm.isTriggered = true;
            vm.variant = response.data.status ? "success" : "danger";
          })
          .catch((error) => {
            vm.variant = "danger";
            console.log(error);
          });

        vm.$root.$emit("bv::hide::modal", "scheduleModal", "#focusThisOnClose");
        vm.show = false;
        vm.$nextTick(() => {
          vm.show = true;
          vm.form.durationTime = vm.tomorrowTime;
          vm.form.durationDate = vm.tomorrowDate;
          vm.form.rateUnit = "";
        });
      } else if (this.multiConnectorActions.includes(action)) {
        this.callCentralSystemApi(chargerId, action, selectedConnector);

        vm.$root.$emit(
          "bv::hide::modal",
          "connectorPickerModal",
          "#focusThisOnClose"
        );
        vm.selectedAction = "";
        vm.selectedConnector = 1;
      } else {
        this.callCentralSystemApi(chargerId, action);
      }
      vm.confirmAlert = false;
      vm.isLoading = true;

      setTimeout(() => {
        vm.isLoading = false;
        vm.dismissCountDown = vm.dismissSecs;
      }, 500);
    },
    testConnection() {
      const vm = this;
      vm.testConnectionInProgress = true;
      vm.checkForEventsFrom = moment().utc().format();
      vm.chargerConnectionStatus = vm.$store.getters.getChargerStatusByChargerId(
        vm.chargerId
      );
      if (
        vm.chargerConnectionStatus !== undefined &&
        vm.chargerConnectionStatus.connected
      ) {
        triggerChargerCommands(this.chargerId, "Disconnect")
          .then(() => {
            vm.disconnectEvent = true;
          })
          .catch((error) => {
            vm.errorWhileTestingConnection = true;
            vm.testConnectionInProgress = false;
            console.log(
              "Error occured while triggering Disconnect command",
              error
            );
            vm.makeToast(
              "Failed!",
              `Testing charger connection failed! Didn't receive Connect command from OCPP!`,
              "danger"
            );
          });
      }
      vm.checkForConnectEventByPolling = setInterval(() => {
        if (vm.testConnectionInProgress) {
          getChargerEventByName(vm.chargerId, vm.checkForEventsFrom, "Connect")
            .then((response) => {
              if (response.data.payload.length > 0) {
                vm.connectEvent = response.data.payload;
                vm.testConnectionResult = "Successful";
                vm.testConnectionInProgress = false;
                clearInterval(this.checkForConnectEventByPolling);
                vm.makeToast(
                  "Success!",
                  "Testing charger connection successful!",
                  "success"
                );
              }
            })
            .catch((error) => {
              vm.errorWhileTestingConnection = true;
              vm.testConnectionInProgress = false;
              vm.makeToast(
                "Failed!",
                `Testing charger connection failed! Didn't receive Connect command from OCPP!`,
                "danger"
              );
              console.log(
                "Error occured while polling connect event for the charger",
                error
              );
            });
        }
      }, 30000);
    },
    markStepAsTimedOut() {
      this.testConnectionInProgress = false;
      this.testConnectionResult = "Failed";
      this.errorWhileTestingConnection = true;
      this.timerSeconds = 59;
      this.timerMinutes = 4;
      this.makeToast(
        "Failed!",
        `Testing charger connection failed! Connection time out`,
        "danger"
      );
    },
    makeToast(title = "", message = "", variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    callCentralSystemApi(chargerId, action, selectedConnector) {
      const vm = this;
      api
        .triggerChargerCommands(chargerId, action, selectedConnector)
        .then(function (response) {
          vm.isTriggered = true;
          vm.variant = response.data.status ? "success" : "danger";
        })
        .catch((error) => {
          vm.variant = "danger";
          console.log(error);
        });
    },
    hideConfirmAlert() {
      const vm = this;
      vm.confirmAlert = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showConfirmAlert(action) {
      const vm = this;
      vm.triggerAction = action;
      vm.dismissCountDown = 0;
      vm.isConfirmLoading = true;
      vm.isCollapse = false;

      setTimeout(() => {
        vm.isConfirmLoading = false;
        vm.confirmAlert = true;
      }, 500);

      vm.confirmAlert = false;
    },
    triggerBtnOnClick() {
      const vm = this;
      vm.isClicked = !vm.isClicked;
      vm.isClicked
        ? vm.$refs.triggerDropdown.show()
        : vm.$refs.triggerDropdown.hide();
    },
    getCloseConnectorPickerModal() {
      this.$refs.connectorPickerModal.hide();
    },
    getCloseScheduleModal() {
      const vm = this;
      vm.form.durationTime = vm.tomorrowTime;
      vm.form.durationDate = vm.tomorrowDate;
      vm.form.rateUnit = "";
      this.$refs.scheduleModal.hide();
    },
    dateFormatter(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    onClick(action) {
      if (this.isMultiConnector) {
        this.selectedAction = action;
        this.$bvModal.show("connectorPickerModal");
      } else {
        this.showConfirmAlert(action);
      }
    },
  },
  computed: {
    ocppVersion() {
      const connectionState = this.$store.getters.getConnectionStateByChargerId(
        this.chargerId
      );
      return connectionState === undefined
        ? null
        : connectionState.ocppProtocol.version;
    },
    isMultiConnector() {
      return this.charger.connectors > 1;
    },
  },
  mounted() {
    setInterval(() => {
      if (this.testConnectionInProgress) {
        this.timerSeconds -= 1;
        if (this.timerSeconds <= 0) {
          this.timerSeconds = 59;
          this.timerMinutes -= 1;
        }
        if (this.timerMinutes < 0) {
          this.markStepAsTimedOut();
        }
      }
    }, 1000);
  },
  beforeMount() {
    const vm = this;
    vm.form.durationTime = vm.tomorrowTime;
    vm.form.durationDate = vm.tomorrowDate;
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>

<style scoped>
.actionBtn {
  width: 225px;
  font-size: 0.8rem;
  justify-content: start;
}

.caret {
  height: 6px;
  width: 15px;
}

.confirmBtn {
  width: 75px;
  height: 30px;
  font-size: 0.9rem;
  justify-content: center;
}
.fetchBtn {
  width: 80px;
  height: 30px;
  font-size: 0.8rem;
  justify-content: center;
}
</style>
