<template>
  <div class="mr-5 ml-5 mt-4 fontStyle">
    <div class="lh-100 mb-0 align-items-center pl-3 pr-3 pb-3 my-3">
      <span class="h4 font-weight-bold">
        <ChargerStatus :chargerId="chargerId" :meterValue="meterValues" />
        {{ charger.serialNumber }} ({{ charger.name }}
        <font-awesome-icon
          icon="edit"
          class="small clickable text-accent"
          title="Edit charger name"
          @click="this.showModal"
        />)
      </span>
    </div>
    <div class="rounded shadow-sm pl-3 pr-3">
      Charger identifier used in OCPP URL:
      <span class="h4 font-weight-bold">{{ charger.externalId }}</span>
      <v-btn icon @click="copyToClipboard(charger.externalId)">
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
    </div>
    <div class="rounded shadow-sm pl-3 pr-3 pb-3 my-3">
      <ChargerEditableInfo
        :chargerId="chargerId"
        :charger="charger"
        :isChargerLoading="isChargerLoading"
      />
    </div>
    <div class="rounded shadow-sm pl-3 pr-3 pb-3 my-3">
      <ChargerDetails
        v-if="!isChargerLoading"
        :chargerId="chargerId"
        :charger="charger"
        :meterValues="meterValues"
      />
      <ChargingProfile :chargerId="chargerId" />
      <ChargerEventsTable
        :chargerId="chargerId"
        :charger="charger"
        :isDisabled="!isOcppDataAvailable"
      />
    </div>

    <b-modal
      id="charger-name-modal"
      ref="charger-name-modal"
      v-model="isModalVisible"
      hide-footer
      title="Edit charger name"
    >
      <template>
        <b-form @submit.stop.prevent="updateChargerName()">
          <b-form-group
            id="charger"
            label="Charger name"
            label-for="chargerName"
          >
            <b-form-input
              id="chargerName"
              @click="clearErrors()"
              v-model="editedName"
            />
          </b-form-group>
          <span class="text-error" v-show="this.errors.name != null">{{
            this.errors.name
          }}</span>
          <span class="text-error" v-show="this.errors.COMMON != null">{{
            this.errors.COMMON
          }}</span>
          <b-button
            size="sm"
            variant="success"
            type="submit"
            class="float-right"
            test-id="cm001-modal-submit"
            >Submit
          </b-button>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ChargerDetails from "@/components/ChargerDetails.vue";
import ChargerEventsTable from "@/components/ChargerEventsTable.vue";
import ChargingProfile from "./ChargingProfile.vue";
import ChargerStatus from "./ChargerStatus.vue";
import ChargerEditableInfo from "@/components/ChargerEditableInfo.vue";
import { getChargerById, updateChargerData } from "@/api/ChargerDetailsApi";
import { getMeterValueForCharger } from "@/api/GetMeterValueApi";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { handleApiErrors } from "@/utils/errorUtils";

library.add(faEdit);

export default {
  name: "detailsView",
  props: ["chargerId"],
  components: {
    ChargerEditableInfo,
    ChargingProfile,
    ChargerDetails,
    ChargerEventsTable,
    ChargerStatus,
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      charger: {},
      isChargerLoading: true,
      meterValues: [],
      editedName: null,
      isModalVisible: false,
      errors: {},
    };
  },
  methods: {
    getCharger() {
      const vm = this;
      const chargerId = vm.chargerId;
      return getChargerById(chargerId)
        .then((response) => {
          vm.charger = response.data;
          vm.isChargerLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    copyToClipboard(string) {
      navigator.clipboard.writeText(string);
    },
    showModal() {
      this.errors = {};
      this.editedName = this.charger.name;
      this.isModalVisible = true;
    },
    hideModal() {
      this.clearErrors();
      this.editedName = null;
      this.isModalVisible = false;
    },
    updateChargerName() {
      updateChargerData(this.charger.externalId, { name: this.editedName })
        .then((response) => {
          console.log("Success");
          this.charger.name = response.data.name;
          this.hideModal();
          this.showSuccessMessage();
        })
        .catch(this.handleErrors);
    },
    clearErrors() {
      this.errorMessage = null;
    },
    handleErrors(error) {
      const apiErrors = handleApiErrors(error);
      if (Object.keys(apiErrors).length !== 0) {
        this.errors = apiErrors;
      } else {
        this.showErrorMessage();
      }
    },
    showSuccessMessage() {
      this.$emit("send-message", true);
    },
    showErrorMessage() {
      this.$emit("send-message", false);
    },
  },
  computed: {
    isOcppDataAvailable() {
      return (
        this.$store.getters.getConnectionStateByChargerId(this.chargerId) !==
        undefined
      );
    },
  },
  beforeMount() {
    const vm = this;
    this.getCharger().then(() => {
      getMeterValueForCharger(vm.chargerId).then(
        (res) => (vm.meterValues = res.data)
      );
    });
    this.$store.dispatch("initEssentialDatasets");
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
