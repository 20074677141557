<template>
  <b-row class="mt-3 pt-3">
    <b-col class="mt-3 pt-5">
      <ChargerCommandsTrigger :chargerId="chargerId" :charger="charger" :isDisabled="isDisabled" />
    </b-col>
    <b-col cols="12" md="9">
      <div class="text-left">
        <b-button
          id="last5min"
          class="filterBtn text-success border border-success mr-1"
          @click="getEventsByTime(lastFiveMins)"
          :disabled="isEventsLoading"
        >
          <span class="btn__text">Last 5 Minutes</span>
          <b-badge
            class="ml-2 mt-1 bg-success"
            v-show="lastFiveMinsEventsNumber"
          >
            {{ lastFiveMinsEventsNumber }}
          </b-badge>
        </b-button>
        <b-button
          id="last10min"
          class="filterBtn text-success border border-success mr-1"
          @click="getEventsByTime(lastTenMins)"
          :disabled="isEventsLoading"
        >
          <span class="btn__text">Last 10 Minutes</span>
          <b-badge
            class="ml-2 mt-1 bg-success"
            v-show="lastTenMinsEventsNumber"
          >
            {{ lastTenMinsEventsNumber }}
          </b-badge>
        </b-button>
        <b-button
          id="last1h"
          class="filterBtn text-success border border-success mr-1"
          @click="getEventsByTime(lastHour)"
          :disabled="isEventsLoading"
        >
          <span class="btn__text">Last 1 Hour</span>
          <b-badge class="ml-2 mt-1 bg-success" v-show="lastHourEventsNumber">
            {{ lastHourEventsNumber }}
          </b-badge>
        </b-button>
        <b-button
          id="last24h"
          class="filterBtn text-success border border-success mr-1 mt-1"
          @click="getEventsByTime(lastDay)"
          :disabled="isEventsLoading"
        >
          <span class="btn__text">Last 24 Hours</span>
          <b-badge class="ml-2 mt-1 bg-success" v-show="lastDayEventsNumber">
            {{ lastDayEventsNumber }}
          </b-badge>
        </b-button>
      </div>
      <div class="text-center mt-3 ml-5" v-if="isEventsLoading">
        <b-spinner type="grow" variant="success" />
      </div>
      <template v-else class="pt-3">
        <template v-if="selectedChargerEvents.length !== 0">
          <div class="pt-4 font-weight-bold">
            Charger Events
          </div>
          <div>
            <b-form-checkbox-group
              v-model="filterOn"
              :options="checkBoxOptions"
              @change="getMessageToggleChange"
              stacked
              variant="success"
              style="column-count: 3; column-gap: 0;"
            />
          </div>
          <b-table-simple bordered style="text-align: center;" class="tbl">
            <b-thead>
              <b-tr>
                <b-th class="w-25 text-left font-weight-bold">Event Name</b-th>
                <b-th class="w-25 font-weight-bold">Timestamp</b-th>
                <b-th class="font-weight-bold">Central System ID</b-th>
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="(charger, index) in selectedChargerEvents"
              :key="index"
            >
              <b-tr v-for="(selected, id) in filterOn" :key="id">
                <template
                  v-if="
                    selected === charger.pduName ||
                    selected === charger.eventName
                  "
                >
                  <b-td>
                    <div
                      class="text-left"
                      v-show="charger.eventType === 'OCPP'"
                    >
                      <b-link v-on:click="toggle(index)">
                        {{ charger.pduName }}
                      </b-link>
                      <span
                        v-if="charger.eventType === 'OCPP'"
                        class="text-grey ml-2"
                        >OCPP
                        {{
                          charger.ocppProtocol.version === "V_16"
                            ? "1.6"
                            : "2.0"
                        }}-{{
                          charger.ocppProtocol.transport === "JSON"
                            ? "J"
                            : charger.ocppProtocol.transport
                        }}</span
                      >
                    </div>
                    <div
                      class="text-left"
                      v-show="charger.eventType === 'CUSTOM'"
                    >
                      <span
                        v-if="
                          charger.payload === null ||
                          charger.payload === undefined
                        "
                      >
                        {{ charger.eventName }}
                      </span>
                      <b-link
                        v-if="
                          charger.payload !== null &&
                          charger.payload !== undefined
                        "
                        v-on:click="toggle(index)"
                      >
                        {{ charger.eventName }}
                      </b-link>
                      <span class="text-grey ml-2">CUSTOM</span>
                    </div>
                  </b-td>
                  <b-td>{{ charger.timestamp }}</b-td>
                  <b-td>{{ charger.centralSystemId }}</b-td>
                </template>
              </b-tr>
              <b-tr v-if="opened.includes(index)">
                <b-td
                  colspan="6"
                  v-show="
                    filterOn.includes(charger.pduName) ||
                    filterOn.includes(charger.eventName)
                  "
                >
                  <vue-json-pretty
                    style="text-align: left;"
                    :data="charger.payload"
                    :deep="3"
                    :show-double-quotes="true"
                    :show-length="false"
                    :show-line="true"
                    :highlight-mouseover-node="true"
                    :collapsed-on-click-brackets="true"
                  >
                  </vue-json-pretty>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
        <b-alert
          show
          v-else-if="hasEvents === false"
          class="mt-3"
          variant="light"
        >
          Events not found
        </b-alert>
        <b-alert
          show
          v-else-if="errorOccured === true"
          class="mt-3"
          variant="light"
        >
          Something went wrong while fetching events
        </b-alert>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormCheckboxGroup,
  BTableSimple,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
  BButton,
  BAlert,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import { getSelectedChargerEvent } from "@/api/ChargerEventsApi.js";
import ChargerCommandsTrigger from "@/components/ChargerCommandsTrigger.vue";
import moment from "moment";
import uniq from "lodash/uniq";

export default {
  name: "ChargerEventsTable",
  props: ["chargerId", "charger", "isDisabled"],
  components: {
    ChargerCommandsTrigger,
    "b-form-checkbox-group": BFormCheckboxGroup,
    "b-table-simple": BTableSimple,
    "b-thead": BThead,
    "b-tr": BTr,
    "b-th": BTh,
    "b-tbody": BTbody,
    "b-td": BTd,
    "vue-json-pretty": VueJsonPretty,
    "b-button": BButton,
    "b-alert": BAlert,
    "b-spinner": BSpinner,
    "b-badge": BBadge,
  },
  data() {
    return {
      opened: [],
      filterOn: [],
      moment: moment,
      currentDateTime: moment().utc().format(),
      lastFiveMins: moment(this.currentDateTime)
        .subtract(5, "minute")
        .utc()
        .format(),
      lastTenMins: moment(this.currentDateTime)
        .subtract(10, "minute")
        .utc()
        .format(),
      lastHour: moment(this.currentDateTime).subtract(1, "hour").utc().format(),
      lastDay: moment(this.currentDateTime).subtract(1, "day").utc().format(),
      checkBoxOptions: [],
      isEventsLoading: false,
      selectedChargerEvents: [],
      lastFiveMinsEventsNumber: "",
      lastTenMinsEventsNumber: "",
      lastHourEventsNumber: "",
      lastDayEventsNumber: "",
      hasEvents: true,
      errorOccured: false,
    };
  },
  methods: {
    getMessageToggleChange() {
      const vm = this;
      vm.opened = [];
    },
    toggle(rowId) {
      const vm = this;
      const index = vm.opened.indexOf(rowId);
      index > -1 ? vm.opened.splice(index, 1) : vm.opened.push(rowId);
    },
    getEventsByTime(fromTime) {
      const vm = this;
      const chargerId = vm.chargerId;

      vm.isEventsLoading = true;

      getSelectedChargerEvent(chargerId, fromTime)
        .then((response) => {
          vm.checkBoxOptions = uniq(
            response.data.payload.map((item) =>
              item.pduName !== undefined ? item.pduName : item.eventName
            )
          );
          vm.filterOn = vm.checkBoxOptions;
          vm.selectedChargerEvents = response.data.payload;
          vm.lastFiveMinsEventsNumber =
            fromTime === vm.lastFiveMins ? response.data.payload.length : "";
          vm.lastTenMinsEventsNumber =
            fromTime === vm.lastTenMins ? response.data.payload.length : "";
          vm.lastHourEventsNumber =
            fromTime === vm.lastHour ? response.data.payload.length : "";
          vm.lastDayEventsNumber =
            fromTime === vm.lastDay ? response.data.payload.length : "";
          vm.isEventsLoading = false;
          if (response.data.payload.length === 0) {
            vm.hasEvents = false;
          }
        })
        .catch((error) => {
          console.log(error);
          vm.isEventsLoading = false;
          vm.errorOccured = true;
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>

<style scoped>
.filterBtn {
  font-size: 0.8rem;
  justify-content: center;
}
</style>
