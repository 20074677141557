import { OCPP_API } from "./common/http-common";

export function getCompositeSchedule(chargerId, duration, rateUnit) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.post(`/${tenant}/centralsystems/getcompositeschedule`, {
    chargerId: chargerId,
    duration: duration,
    connectorId: 0,
    chargingRateUnitType: rateUnit,
  }).catch(function (error) {
    console.log(error);
  });
}
