<template>
  <div class="border-bottom pb-3">
    <div class="text-center mt-3 ml-5" v-if="isDetailLoading">
      <b-spinner />
    </div>
    <div v-else>
      <b-row class="fontStyle justify-content-between">
        <template v-if="Object.keys(selectedChargerDetails).length !== 0">
          <b-list-group>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Charge Point Vendor:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.chargePointVendor }}
              </b-list-group-item>
            </span>
            <template>
              <div>
                <span class="d-flex justify-content-between">
                  <b-list-group-item class="font-weight-bold border-0">
                    Vendor:
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    {{
                      selectedChargerConnectionState === undefined
                        ? "N/A"
                        : selectedChargerConnectionState.vendor
                    }}
                  </b-list-group-item>
                </span>
                <span class="d-flex justify-content-between">
                  <b-list-group-item class="font-weight-bold border-0">
                    OCPP Version:
                  </b-list-group-item>
                  <b-list-group-item class="border-0">
                    {{
                      selectedChargerConnectionState === undefined
                        ? "N/A"
                        : selectedChargerConnectionState.ocppProtocol.version
                    }}
                  </b-list-group-item>
                </span>
                <span class="d-flex justify-content-between">
                  <b-list-group-item class="font-weight-bold border-0">
                    Ownership:
                  </b-list-group-item>
                  <b-list-group-item
                    v-if="!selectedChargerConnectionState"
                    class="border-0"
                  >
                    N/A
                  </b-list-group-item>
                  <b-list-group-item v-else class="border-0">
                    <div>
                      <b-form-select
                        v-model="chargerOwnership"
                        :options="chargerOwnershipOptionsComputed"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null">{{
                            selectedChargerConnectionState.ownership
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                    <div style="margin-top: 0.5rem; text-align: right">
                      <b-button
                        size="sm"
                        variant="outline-success"
                        :disabled="chargerOwnership === null"
                        @click="updateChargerOwnership()"
                        >Save Ownership</b-button
                      >
                    </div>
                  </b-list-group-item>
                </span>
                <span class="d-flex justify-content-between">
                  <b-list-group-item class="font-weight-bold border-0">
                    Charger Status:
                  </b-list-group-item>
                  <b-list-group-item v-if="!charger" class="border-0">
                    N/A
                  </b-list-group-item>
                  <b-list-group-item v-else class="border-0">
                    <div>
                      <b-form-select
                        v-model="chargerStatus"
                        :options="chargerStatusOptionsComputed"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="chargerStatus">{{
                            chargerStatus
                          }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                    <div style="margin-top: 0.5rem; text-align: right">
                      <b-button
                        size="sm"
                        variant="outline-success"
                        :disabled="shouldStatusChangeButtonBeDisabled"
                        @click="showStatusChangeConfirmAlert()"
                        >Save Status</b-button
                      >
                    </div>
                  </b-list-group-item>
                </span>
              </div>
            </template>
          </b-list-group>
          <b-list-group class="mr-5">
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Firmware Version:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.firmwareVersion }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Charger Serial Number:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.chargePointSerialNumber }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Box Serial Number:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.chargeBoxSerialNumber }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Meter Serial Number:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.meterSerialNumber }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Authorization Key:
              </b-list-group-item>
              <b-list-group-item
                v-if="!isChargerAuthDataAvailable || !showAuthCode"
                class="border-0"
              >
                N/A
              </b-list-group-item>
              <b-list-group-item class="border-0" v-else>
                {{ this.shortenedAuthCode }}
                <v-btn
                  v-show="this.authCode !== ''"
                  icon
                  @click="copyToClipboard(authCode)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </b-list-group-item>
            </span>
            <div style="margin-top: 0.5rem; text-align: right">
              <b-button
                size="sm"
                variant="outline-success"
                :disabled="this.charger.status === 'OPERATIONAL'"
                @click="showAuthKeyGenerationConfirmAlert()"
                >Generate Auth Key</b-button
              >
            </div>
          </b-list-group>
          <b-list-group class="mr-5">
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Meter Type:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.meterType }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Charge Point Model:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.chargePointModel }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                ICCID:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.iccid }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                IMSI:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ selectedChargerDetails.imsi }}
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Current OTP:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                {{ this.otp }}
                <v-btn
                  v-show="this.otp !== ''"
                  icon
                  @click="copyToClipboard(otp)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </b-list-group-item>
            </span>
            <div style="margin-top: 0.5rem; text-align: right">
              <b-button
                size="sm"
                variant="outline-success"
                @click="generateOtp()"
                >Generate OTP</b-button
              >
            </div>
          </b-list-group>
          <b-list-group class="mr-5">
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Meter Value:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                <SelectedMeterValue
                  :chargerId="chargerId"
                  :rawMeterValues="meterValues"
                />
              </b-list-group-item>
            </span>
            <span class="d-flex justify-content-between">
              <b-list-group-item class="font-weight-bold border-0">
                Charging Rate Limit:
              </b-list-group-item>
              <b-list-group-item class="border-0">
                <div v-if="charger.useLocalLoadBalancing">
                  Currently managed by an external system!
                </div>
                <div v-else>
                  <div class="box">
                    <input v-model="charger.chargingRateLimit" />
                    {{ charger.chargingRateUnitType }}
                  </div>
                  <br />
                  <br />
                  <vue-slider
                    v-model="charger.chargingRateLimit"
                    :min="charger.minChargingRateLimit"
                    :max="charger.maxChargingRateLimit"
                    :tooltip="'always'"
                    :interval="0.1"
                    :marks="[
                      charger.minChargingRateLimit,
                      charger.maxChargingRateLimit,
                    ]"
                  />
                  <br />
                  <div>
                    <b-button
                      size="sm"
                      variant="outline-success"
                      @click="updateChargingRateLimit()"
                      >Save</b-button
                    >
                  </div>
                </div>
              </b-list-group-item>
            </span>
          </b-list-group>
        </template>
        <b-alert show v-else variant="danger" class="ml-3">
          No details
        </b-alert>
      </b-row>
      <div class="text-center">
        <b-alert
          :show="confirmStatusChangeAlert"
          class="fixed-top"
          variant="light"
        >
          <span class="text-dark">
            {{ statusChangeMessage }}
          </span>
          <div class="mt-3">
            <b-button
              class="btn--secondary"
              @click="updateChargerStatus(chargerStatus)"
            >
              <font-awesome-icon icon="check" class="mr-1" />
              {{
                chargerStatus !== "OPERATIONAL"
                  ? `You are about to change the status to ${chargerStatus}. This will make the charger inoperable for the customer.`
                  : `You are about to change the status to ${chargerStatus}. This will make the charger operable for the customer. `
              }}
            </b-button>
            <b-button
              class="btn--primary"
              @click="hideStatusChangeConfirmAlert"
            >
              <svg class="icon mr-1">
                <use xlink:href="@/assets/svg/icons.svg#x" />
              </svg>
              Cancel
            </b-button>
          </div>
        </b-alert>
      </div>
      <div class="text-center">
        <b-alert
          :show="confirmAuthKeyGenerationAlert"
          class="fixed-top"
          variant="light"
        >
          <span class="text-dark">
            {{ authKeyGenerationChangeMessage }}
          </span>
          <div class="mt-3">
            <b-button class="btn--secondary" @click="generateAuth()">
              <font-awesome-icon icon="check" class="mr-1" />
              {{
                authCode === "" || shortenedAuthCode === ""
                  ? `You are about to generate a new authorization key. `
                  : `You are about to generate a new authorization key. This will invalidate the current auth code and the charger has to be reconfigured to use the new key. `
              }}
            </b-button>
            <b-button
              class="btn--primary"
              @click="hideAuthKeyGenerationConfirmAlert"
            >
              <svg class="icon mr-1">
                <use xlink:href="@/assets/svg/icons.svg#x" />
              </svg>
              Cancel
            </b-button>
          </div>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormSelect,
} from "bootstrap-vue";
import {
  getSelectedChargerDetail,
  updateChargerData,
} from "@/api/ChargerDetailsApi";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import SelectedMeterValue from "./SelectedMeterValue";
import { createOtp, getOtpForCharger } from "@/api/OneTimePasswordApi";
import { changeChargerAuth, getChargerAuth } from "@/api/ChargerAuthApi";
import { copyToClipboard, randomString } from "@/utils/onboardingUtils";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "chargerDetails",
  props: ["chargerId", "charger", "meterValues"],
  components: {
    SelectedMeterValue,
    VueSlider,
    "b-alert": BAlert,
    "b-spinner": BSpinner,
    "b-list-group": BListGroup,
    "b-list-group-item": BListGroupItem,
    "b-form-select": BFormSelect,
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      selectedChargerDetails: {},
      isDetailLoading: true,
      isOcppDataAvailable: false,
      isChargerAuthDataAvailable: false,
      chargerOwnership: null,
      chargerStatus: "",
      isStatusChanged: false,
      errorWhileUpdatingChargerStatus: false,
      updatingChargerStatusInProgress: false,
      errorWhileGeneratingOtp: false,
      generatingOtpInProgress: false,
      fetchingOtpInProgress: true,
      clickedOnCopy: false,
      otp: null,
      getAuthKeyInProgress: false,
      errorWhileGettingAuthKey: false,
      authCode: "",
      shortenedAuthCode: "",
      showAuthCode: false,
      confirmStatusChangeAlert: false,
      confirmAuthKeyGenerationAlert: false,
      statusChangeMessage:
        "You are about to change the current status of the charger!",
      authKeyGenerationChangeMessage:
        "You are about to generate a new auth key for the charger!",
      chargerOwnershipOptions: [
        { value: "PRIVATE", text: "PRIVATE" },
        { value: "SEMI_PRIVATE", text: "SEMI-PRIVATE" },
      ],
      chargerStatusOptions: [
        { value: "OPERATIONAL", text: "OPERATIONAL" },
        { value: "ONBOARDING", text: "ONBOARDING" },
      ],
    };
  },
  methods: {
    copyToClipboard,
    updateChargerStatus(status) {
      this.hideStatusChangeConfirmAlert();
      this.updatingChargerStatusInProgress = true;
      updateChargerData(this.chargerId, { status: status })
        .then((response) => {
          this.isStatusChanged = true;
          this.updatingChargerStatusInProgress = false;
          this.errorWhileUpdatingChargerStatus = false;
          this.chargerStatus = response.data.status;
          this.charger.status = response.data.status;
          this.generateOtpAndSetAuthShowValue();
          this.$bvToast.toast(
            `Update successful! Charger status is now changed to ${this.chargerStatus}`,
            {
              title: "Charger status value",
              autoHideDelay: 4000,
              variant: "success",
            }
          );
        })
        .catch((error) => {
          this.errorWhileUpdatingChargerStatus = true;
          this.updatingChargerStatusInProgress = false;
          this.isStatusChanged = false;
          console.log(
            "Error occured while changing status to operational",
            error
          );
          this.$bvToast.toast(
            `Update unsuccessful! Charger status is still ${this.chargerStatus}`,
            {
              title: "Charger status value",
              autoHideDelay: 4000,
              variant: "danger",
            }
          );
        });
    },
    hideStatusChangeConfirmAlert() {
      const vm = this;
      vm.confirmStatusChangeAlert = false;
    },
    showStatusChangeConfirmAlert() {
      const vm = this;
      vm.isConfirmLoading = true;

      setTimeout(() => {
        vm.isConfirmLoading = false;
        vm.confirmStatusChangeAlert = true;
      }, 500);
    },
    hideAuthKeyGenerationConfirmAlert() {
      const vm = this;
      vm.confirmAuthKeyGenerationAlert = false;
    },
    showAuthKeyGenerationConfirmAlert() {
      const vm = this;
      vm.isConfirmLoading = true;

      setTimeout(() => {
        vm.isConfirmLoading = false;
        vm.confirmAuthKeyGenerationAlert = true;
      }, 500);
    },
    generateOtpAndSetAuthShowValue() {
      if (this.chargerStatus === "OPERATIONAL") {
        this.showAuthCode = false;
        if (this.otp === "") {
          this.generateOtp();
        }
      } else if (this.chargerStatus === "ONBOARDING") {
        this.showAuthCode = true;
      }
    },
    generateAuth() {
      const vm = this;
      vm.hideAuthKeyGenerationConfirmAlert();
      const ocppEndPoint = `${process.env.VUE_APP_ENDPOINT_URI}`;
      const chargerId = this.chargerId;
      const authKey = randomString();
      vm.authCode = `wss://${chargerId}:${authKey}@${ocppEndPoint}/ocpp/${chargerId}`;
      vm.shortenedAuthCode = `${chargerId}:${authKey}`;
      this.saveAuth(vm.charger.id, authKey);
    },
    saveAuth(id, authKey) {
      this.savingAuthKeyInProgress = true;
      changeChargerAuth(id, authKey)
        .then(() => {
          this.errorWhileSavingAuthKey = false;
          this.savingAuthKeyInProgress = false;
          this.showAuthCode = true;
          this.isChargerAuthDataAvailable = true;
        })
        .catch((error) => {
          this.errorWhileSavingAuthKey = true;
          this.savingAuthKeyInProgress = false;
          console.log("error occured while saving auth key", error);
        });
    },
    setInitialChargerStatusAndShowAuthKeyValue() {
      this.chargerStatus = this.charger.status;
      this.showAuthCode = this.chargerStatus !== "OPERATIONAL";
    },
    getAuthKey() {
      const vm = this;
      vm.getAuthKeyInProgress = true;
      const externalId = vm.chargerId;
      const chargerId = vm.charger.id;

      getChargerAuth(chargerId)
        .then((response) => {
          const ocppEndPoint = `${process.env.VUE_APP_ENDPOINT_URI}`;
          const authKey = response.data.unmaskedAuthKey;
          vm.authCode = `wss://${externalId}:${authKey}@${ocppEndPoint}/ocpp/${externalId}`;
          vm.shortenedAuthCode = `${externalId}:${authKey}`;
          vm.isChargerAuthDataAvailable = true;
        })
        .catch((error) => {
          vm.errorWhileGettingAuthKey = true;
          console.log("error occured while getting auth key", error);
        })
        .finally(() => {
          vm.getAuthKeyInProgress = false;
        });
    },
    getExistingOtpForCharger() {
      this.fetchingOtpInProgress = true;
      getOtpForCharger(this.chargerId)
        .then((response) => {
          const customerCharger = response.data[0];
          if (!customerCharger) {
            return;
          }

          if (customerCharger.customerType === "REAL") {
            this.isRealCustomerLinked = true;
          } else if (customerCharger.otp) {
            this.otp = customerCharger.otp;
          }
        })
        .catch(() => {
          this.isRealCustomerLinked = false;
          this.otp = "";
        })
        .finally(() => {
          this.fetchingOtpInProgress = false;
        });
    },
    generateOtp() {
      this.generatingOtpInProgress = true;
      const chargerId = this.charger.id;
      createOtp(chargerId)
        .then((response) => {
          this.otp = response.data.otp;
          this.errorWhileGeneratingOtp = false;
        })
        .catch((error) => {
          this.errorWhileGeneratingOtp = true;
          this.$bvToast.toast(
            `OTP generation failed! Error: ${error.response.data.detail}`,
            {
              title: "OTP generation failed!",
              autoHideDelay: 4000,
              variant: "danger",
            }
          );
        })
        .finally(() => {
          this.generatingOtpInProgress = false;
        });
    },
    getSelectedChargerDetails() {
      const vm = this;
      const chargerId = vm.chargerId;
      getSelectedChargerDetail(chargerId)
        .then((response) => {
          vm.selectedChargerDetails = response.data;
          vm.isOcppDataAvailable = true;
        })
        .catch((error) => {
          const notAvailable = "N/A";
          vm.selectedChargerDetails.chargePointVendor = notAvailable;
          vm.selectedChargerDetails.chargePointModel = notAvailable;
          vm.selectedChargerDetails.ocppProtocol = {};
          vm.selectedChargerDetails.firmwareVersion = notAvailable;
          vm.selectedChargerDetails.chargePointSerialNumber = notAvailable;
          vm.selectedChargerDetails.chargeBoxSerialNumber = notAvailable;
          vm.selectedChargerDetails.meterSerialNumber = notAvailable;
          vm.selectedChargerDetails.meterType = notAvailable;
          vm.selectedChargerDetails.iccid = notAvailable;
          vm.selectedChargerDetails.imsi = notAvailable;
          console.log(error);
        })
        .finally(() => {
          vm.isDetailLoading = false;
        });
    },
    performChargerUpdate(chargerId, data, title, successMessage, failMessage) {
      updateChargerData(chargerId, data)
        .then(() => {
          this.$bvToast.toast(successMessage, {
            title: title,
            autoHideDelay: 8000,
            variant: "success",
          });
        })
        .catch((error) => {
          this.$bvToast.toast(failMessage, {
            title: title,
            autoHideDelay: 8000,
            variant: "danger",
          });
          console.log(error);
        });
    },

    updateChargingRateLimit() {
      const vm = this;
      this.performChargerUpdate(
        vm.chargerId,
        { chargingRateLimit: vm.charger.chargingRateLimit },
        "Charging Rate Limit value ",
        "Update successful!",
        "Update failed! Please ensure that the new value is between the given range."
      );
    },
    updateChargerOwnership() {
      const vm = this;
      this.performChargerUpdate(
        vm.chargerId,
        { ownership: vm.chargerOwnership },
        "Charger ownership value ",
        "Update successful! Charger re-connect is required for the changes to take effect",
        "Update failed!"
      );
    },
  },
  computed: {
    selectedChargerConnectionState() {
      return this.$store.getters.getConnectionStateByChargerId(this.chargerId);
    },
    chargerOwnershipOptionsComputed() {
      return this.chargerOwnershipOptions.filter(
        (x) => x.value !== this.selectedChargerConnectionState.ownership
      );
    },
    chargerStatusOptionsComputed() {
      return this.chargerStatusOptions.filter(
        (x) => x.value !== this.chargerStatus
      );
    },
    shouldStatusChangeButtonBeDisabled() {
      const vm = this;

      return (
        vm.chargerStatus === null || vm.chargerStatus === vm.charger.status
      );
    },
  },
  beforeMount() {
    this.getSelectedChargerDetails();
    this.getExistingOtpForCharger();
    this.getAuthKey();
    this.setInitialChargerStatusAndShowAuthKeyValue();
  },
};
</script>

<style scoped>
.alert {
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  margin-bottom: 1rem;
  background-color: #eff1f4;

  .icon {
    width: 1em;
    height: 1em;
  }

  .btn--primary {
    background-color: #fff;
    box-shadow: none;
    font-size: 0.8rem;
    color: #0b7d45;
  }

  .btn--secondary {
    background-color: #fff;
    box-shadow: none;
    font-size: 0.8rem;
    color: #000;
    margin-right: 2rem;
  }
}
</style>
