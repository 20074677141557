import { OCPP_API } from "./common/http-common";

export function putDiagnostics(chargerId) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.put(`/${tenant}/centralsystems/getdiagnostics`, {
    chargerId: chargerId,
  }).catch(function (error) {
    console.log(error);
  });
}
