<template>
  <div class="mt-4">
    <div>
      <b-table-simple responsive class="tbl text-center" bordered>
        <b-thead>
          <b-tr>
            <b-th class="font-weight-bold">Connector ID</b-th>
            <b-th class="font-weight-bold">Profile ID</b-th>
            <b-th class="font-weight-bold">Transaction ID</b-th>
            <b-th class="font-weight-bold">Profile Purpose Type</b-th>
            <b-th class="font-weight-bold">Profile Kind Type</b-th>
            <b-th class="font-weight-bold">Recurrency Kind</b-th>
            <b-th class="font-weight-bold">Charging Rate Unit</b-th>
            <b-th class="font-weight-bold">Schedule Period</b-th>
            <b-th class="font-weight-bold">Minimum Charging Rate </b-th>
            <b-th class="font-weight-bold">Stack Level</b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          v-for="(chargingProfile, i) in chargingProfiles"
          :key="chargingProfile.wrapperMessageId + i"
        >
          <b-tr>
            <b-td>{{ chargingProfile.connectorId }}</b-td>
            <b-td>{{ chargingProfile.chargingProfileId }}</b-td>
            <b-td>{{ chargingProfile.transactionId }}</b-td>
            <b-td> {{ chargingProfile.chargingProfilePurposeType }}</b-td>
            <b-td>{{ chargingProfile.chargingProfileKindType }}</b-td>
            <b-td>{{ chargingProfile.recurrencyKind }}</b-td>
            <b-td>{{ chargingProfile.chargingRateUnitType }}</b-td>
            <b-td
              >startPeriod:{{ chargingProfile.startPeriod }} / limit:
              {{ chargingProfile.limit }} / numberPhases:{{
                chargingProfile.numberPhases
              }}</b-td
            >
            <b-td
              >{{ chargingProfile.minChargingRate }}
              {{ chargingProfile.chargingRateUnitType }}</b-td
            >
            <b-td>{{ chargingProfile.stackLevel }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTbody, BTd } from "bootstrap-vue";
import { getChargingProfile } from "@/api/ChargerDetailsApi.js";
export default {
  name: "chargingProfile",
  props: ["chargerId"],
  components: {
    "b-table-simple": BTableSimple,
    "b-thead": BThead,
    "b-tr": BTr,
    "b-th": BTh,
    "b-tbody": BTbody,
    "b-td": BTd,
  },
  data() {
    return {
      chargingProfiles: {},
    };
  },
  methods: {
    getChargingProfile() {
      const vm = this;
      getChargingProfile(vm.chargerId)
        .then((response) => {
          vm.chargingProfiles = response.data;
        })
        .catch((error) => {
          console.log("Error occured while getting charging profile", error);
        });
    },
  },
  beforeMount() {
    this.getChargingProfile();
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
