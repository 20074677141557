<template>
  <div>
    <b-row>
      <span class="stretch d-flex justify-content-between">
        <b-col sm="1" align-self="top">
          <b-list-group-item class="font-weight-bold border-0">
            <label class="label"> Notes: </label>
          </b-list-group-item>
        </b-col>
        <b-col sm="10" align-self="top">
          <b-list-group-item class="stretch border-0">
            <b-form-textarea
              v-if="isChargerLoading"
              placeholder="Loading..."
              :disabled="true"
            />
            <b-form-textarea
              v-else
              :maxlength="notesMaxLength"
              v-model="charger.notes"
            />
          </b-list-group-item>
        </b-col>
        <b-col sm="1" align-self="top">
          <b-list-group-item class="border-0">
            <b-button
              variant="outline-success"
              @click="updateChargerNotes()"
              :disabled="isChargerLoading"
            >
              Save
            </b-button>
          </b-list-group-item>
        </b-col>
      </span>
    </b-row>

    <b-row>
      <span class="stretch d-flex justify-content-between">
        <b-col sm="1" align-self="top">
          <b-list-group-item class="font-weight-bold border-0">
            <label class="label"> Address: </label>
          </b-list-group-item>
        </b-col>
        <b-col sm="10" align-self="top">
          <b-list-group-item class="stretch border-0">
            <b-form-input
              v-if="isChargerLoading"
              placeholder="Loading..."
              :disabled="true"
            />
            <b-form-input
              v-else
              @input="validateAddress"
              :state="addressValidation"
              v-model="charger.address"
            />
            <b-form-invalid-feedback>
              Address needs to be 3 to 128 characters
            </b-form-invalid-feedback>
          </b-list-group-item>
        </b-col>
        <b-col sm="1" align-self="top">
          <b-list-group-item class="border-0">
            <b-button
              variant="outline-success"
              @click="updateChargerAddress()"
              :disabled="isChargerLoading"
            >
              Save
            </b-button>
          </b-list-group-item>
        </b-col>
      </span>
    </b-row>
  </div>
</template>

<script>
import { updateChargerData } from "@/api/ChargerDetailsApi";
import { BFormTextarea } from "bootstrap-vue";

export default {
  name: "chargerNotes",
  props: ["chargerId", "charger", "isChargerLoading"],
  components: {
    "b-form-textarea": BFormTextarea,
  },
  data() {
    return {
      notesMaxLength: 255,
      addressMaxLength: 128,
      addressMinLength: 3,
      addressValidation: null,
    };
  },
  methods: {
    validateAddress() {
      if (this.charger.address == null) {
        return;
      }
      const addressLength = this.charger.address.length;
      this.addressValidation =
        addressLength >= this.addressMinLength &&
        addressLength <= this.addressMaxLength;
    },
    performChargerUpdate(chargerId, chargerData, title) {
      updateChargerData(chargerId, chargerData)
        .then(() => {
          this.$bvToast.toast(`Update successful`, {
            title: title,
            autoHideDelay: 5000,
            variant: "success",
          });
        })
        .catch((error) => {
          this.$bvToast.toast(`Update failed!`, {
            title: title,
            autoHideDelay: 5000,
            variant: "danger",
          });
          console.log(error);
        });
    },
    updateChargerNotes() {
      const vm = this;
      const chargerId = vm.chargerId;
      const chargerNotes = vm.charger.notes;
      this.performChargerUpdate(
        chargerId,
        { notes: chargerNotes },
        "Charger notes"
      );
    },
    updateChargerAddress() {
      const vm = this;
      if (!vm.addressValidation) {
        return;
      }
      const chargerId = vm.chargerId;
      const chargerAddress = vm.charger.address;
      this.performChargerUpdate(
        chargerId,
        { address: chargerAddress },
        "Charger address"
      );
    },
  },
};
</script>

<style scoped>
.label {
  padding-top: 12%;
}
.stretch {
  width: 100%;
}
</style>
